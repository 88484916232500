'use client'
import localFont from 'next/font/local'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/a11y'
import '../styles/main.scss'

const graphikFont = localFont({
  src: [
    {
      path: '../fonts/graphik-regular/graphik-regular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/graphik-bold/graphik-bold.woff',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/graphik-semibold/graphik-semibold.woff',
      weight: '600',
      style: 'normal',
    },
  ],
})

export default function RootLayout({ children }) {
  return (
    <html lang='en'>
      <body className={`${graphikFont.className} root-body`}>{children}</body>
    </html>
  )
}
